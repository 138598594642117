import React, { useState } from "react"
import * as styles from "./herosection2.module.scss"
import { Col, Container, Row } from "react-bootstrap"
import { PopupModal } from "react-calendly"

const HeroSectionHomePage = ({ newHeroSection }) => {
  const [showCalendly, setShowCalendly] = useState(false)

  return (
    <div className={`containerfluid ${styles.mainWrapper}`}>
      <video
        className={styles.backgroundVideo}
        autoPlay
        muted
        loop
        poster="https://invozone-backend.s3.us-east-1.amazonaws.com/hero_section_1_53984860ed.webp"
      >
        <source
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/Home_Page_Video_Final_Format_8fe0b0e15f.mp4"
          }
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <div className={styles.backgroundImage}></div>
      <div className={styles.content}>
        <Container>
          <Row className="align-items-center">
            <Col lg={5}>
              <h1 className={styles.subHeading}>
                Software Development Consulting
              </h1>
              <p className={styles.heading} style={{ fontDisplay: "swap" }}>
                Future-Driven Innovations.
              </p>
              <p className={styles.dec}>
                Fostering Growth For Startups, Enterprises, And Innovators.
              </p>
              <div className={styles.claedlyContainer}>
                <div
                  className={styles.claedly}
                  style={{ position: "relative" }}
                >
                  <div className={styles.animateBg}>
                    <i className={styles.ripple1}></i>
                    <i className={styles.ripple2}></i>
                    <i className={styles.ripple3}></i>
                  </div>
                  <img
                    className={styles.waveShadow}
                    src="https://invozone-backend.s3.us-east-1.amazonaws.com/claendly_8606ad6fd7.svg"
                    alt="claendly"
                    style={{
                      width: "42px",
                      height: "42px",
                    }}
                  />
                </div>

                <div onClick={() => setShowCalendly(true)}>
                  <p className={styles.claedlyDec}>Book a 15 min Call</p>
                </div>
                <PopupModal
                  url="https://calendly.com/invo/home"
                  onModalClose={() => setShowCalendly(false)}
                  open={showCalendly}
                  rootElement={
                    typeof window !== "undefined"
                      ? document.getElementById("___gatsby")
                      : null
                  }
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.imageBelowContent}>
        <img
          src="https://invogames.s3.us-east-2.amazonaws.com/Black_Gradient_449783e742.svg"
          alt="Additional Content"
          className="w-100 h-100"
        />
      </div>
    </div>
  )
}

export default HeroSectionHomePage
